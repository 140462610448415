import { Component } from "react"
import { AmplifySignOut } from "@aws-amplify/ui-react"

import { Role } from "./role.js"

import banner from "../images/banner.png"

export default class Header extends Component {
  render() {
    return (
      <section className="home-dark" style={{ paddingTop: "0", paddingBottom: "0" }}>
        <img src={banner} width="100%" alt="Welcome logo" />
        <div className="row column large-10">
          <table width="100%">
            {/* Display this header for ADMIN roles */}
            {this.props.role.includes(Role.Admin) && (
              <tbody>
                <tr>
                  <td width="20%">
                    <a href="/admin" className="menu">
                      Admin
                    </a>
                  </td>
                  <td width="20%">
                    <a href="/" className="menu">
                      Home
                    </a>
                  </td>
                  <td width="20%">
                    <a href="/locations" className="menu">
                      Locations
                    </a>
                  </td>
                  <td width="20%">
                    <a href="/analysis" className="menu">
                      Analysis
                    </a>
                  </td>
                  <td width="10%">
                    <AmplifySignOut />
                  </td>
                </tr>
              </tbody>
            )}

            {/* Display this header for STUDENT roles */}
            {this.props.role.includes(Role.Student) && (
              <tbody>
                <tr>
                  <td width="25%">
                    <a href="/" className="menu">
                      Home
                    </a>
                  </td>
                  <td width="25%">
                    <a href="/locations" className="menu">
                      Locations
                    </a>
                  </td>
                  <td width="25%">
                    <a href="/analysis" className="menu">
                      Analysis
                    </a>
                  </td>
                  <td width="25%">
                    <AmplifySignOut />
                  </td>
                </tr>
              </tbody>
            )}

            {/* Display this header for UNASSIGNED roles */}
            {this.props.role.includes(Role.Unassigned) && (
              <tbody>
                <tr>
                  <td width="30%"></td>
                  <td width="25%">
                    <a href="/" className="menu">
                      Home
                    </a>
                  </td>
                  <td width="20%">
                    <AmplifySignOut />
                  </td>
                  <td width="30%"></td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </section>
    )
  }
}
