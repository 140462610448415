import CircularProgress from "@material-ui/core/CircularProgress"

import banner from "../images/banner.png"

const Loading = () => {
  return (
    <div>
      <section className="home-dark" style={{ paddingTop: "0", paddingBottom: "0" }}>
        <img src={banner} width="100%" alt="Welcome logo" />
      </section>

      <section className="home-light">
        <CircularProgress color="secondary" size="1.5rem" />
      </section>
    </div>
  )
}

export default Loading
